import axios from 'axios'
import param from '../parameters.js'

export default {
    namespaced: true,
    state: {
        incomingDetail: null,
        elementList: [],
        statusList: [],
        loadingStatus: true
    },
    getters: {
        deliveryDate(state){
            return state.incomingDetail.order.deliveryDate + ' / ' + state.incomingDetail.order.deliveryTime.name 
        },
        telephone(state){
            if (!state.incomingDetail.user.telephone){
                return ['span', 'Скрыт']
            }
            return ['a', state.incomingDetail.user.telephone]
        },
        hasIncomingChanges(state){
            if (state.incomingDetail && 
                state.incomingDetail.incomingChanges && 
                Object.keys(state.incomingDetail.incomingChanges).length > 0
            ){
                return true
            }
            return false
        },
        isAllElementsProcessed(state){
            if (state.incomingDetail && 
                state.incomingDetail.incomingChanges && 
                Object.keys(state.incomingDetail.incomingChanges).length > 0
            ){
                for (const el of state.elementList){
                    if (!el.incomingElementChanges || !Object.keys(el.incomingElementChanges).length > 0){
                        return
                    }
                }
                return true
            }
            return false
        },
        breadcrumbItems(state){
            if (state.incomingDetail){
                return [{label: '№ ' + state.incomingDetail.order.id, to: state.incomingDetail.id.toString()}]
            }
            return []
        },
    },
    mutations: {
        setLoadingStatus(state, status){
            state.loadingStatus = status
        },

        statusList(state, data){
            state.statusList = data
        },

        incomingDetail(state, data){
            data['commentModel'] = data.comment
            data['statusModel'] = data.status.shortname
            if (data.incomingChanges && Object.keys(data.incomingChanges).length > 0){
                data['commentModel'] = data.incomingChanges.comment
                data['statusModel'] = data.incomingChanges.status.shortname
            }
            state.incomingDetail = data
        },
        elementList(state, data){  
            var indForDel = []

            data.sort((a, b) => 
                a.element.name.localeCompare(b.element.name)
            )
            data.forEach((el, ind) => {
                if (el.amount <= 0) { //Удаляем элементы с нулевым(или меньшим) к-вом товара (Есть возможность что с сервера придут товары с 0 к-вом, оператору их видеть не нужно)
                    indForDel.push(ind)
                } else {
                    el['ind'] = ind + 1
                    el['amountModel'] = el.incomingElementChanges && el.incomingElementChanges.amount >= 0 ? el.incomingElementChanges.amount : el.amount
                }
            })

            for (var i = indForDel.length - 1; i >= 0; i--)
                data.splice(indForDel[i], 1)
            state.elementList = data
        },
        updateElement(state, data){
            const ind = state.elementList.findIndex(item => item.element.id === data.element.id)
            state.elementList[ind] = data
            state.elementList.forEach((el, ind) => {
                el['ind'] = ind + 1
                el['amountModel'] = el.incomingElementChanges && el.incomingElementChanges.amount >= 0 ? el.incomingElementChanges.amount : el.amount
            })
        },
        createIncomingChangesWithoutRequest(state){
            if (state.incomingDetail && !state.incomingDetail.incomingChanges)
                state.incomingDetail.incomingChanges = {comment: state.incomingDetail.comment} 
        },
        logout(state){
            state.incomingDetail = null
            state.elementList = []
            state.statusList = []
            state.loadingStatus = true
        }
    },
    actions: {
        incomingDetail({commit, rootGetters}, incomingId){
            return axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/incomings/' + incomingId,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            }).
            then(res=>{
                commit('incomingDetail', res.data)
            })
        },
        elementList({commit, rootGetters}, incoming){
            return axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/incomingelements',
                params: {incoming},
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            }).
            then(res=>{
                commit('elementList', res.data)
            })
        },
        postIncomingChanges({rootGetters}, data){
            return axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/incomingchanges',
                data,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            })
        },
        patchIncomingChanges({rootGetters}, {data, incomingId}){
            return axios({
                method: 'patch',
                url: process.env.VUE_APP_API_URL + '/incomingchanges/' + incomingId,
                data,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            })
        },

        deleteIncomingChanges({rootGetters}, incomingId){
            return axios({
                method: 'delete',
                url: process.env.VUE_APP_API_URL + '/incomingchanges/' + incomingId,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            })
        },
        
        IncomingChanges({dispatch, getters}, {data, incomingId}){
            if (getters.hasIncomingChanges){
                return dispatch('patchIncomingChanges', {data, incomingId})
            } else {
                data.incoming = incomingId
                return dispatch('postIncomingChanges', data)
            }
        },

        saveComment({state, dispatch, commit}, incomingId){
            var data = {
                comment: state.incomingDetail.commentModel,
                status: state.incomingDetail.statusModel
            }
            return dispatch('IncomingChanges', {data, incomingId}).
            then(()=>{
                commit('createIncomingChangesWithoutRequest') //При создании изменения ответ от сервера не приходит, создаем вручную.
            })
        },

        changeStatusIfNeed({state, dispatch}, incomingId){
            var isDifferent = false
            for (const el of state.elementList){
                if (!el.incomingElementChanges || !Object.keys(el.incomingElementChanges).length > 0){
                    return
                }
                if (el.incomingElementChanges.amount != el.amount){
                    isDifferent = true
                }
            }

            var data = {
                status: null
            }

            if (isDifferent){
                data.status = param.STATUS_CHANGED
            } else {
                data.status = param.STATUS_DONE
            }

            return dispatch('IncomingChanges', {data, incomingId}).
            then(()=>{
                state.incomingDetail.statusModel = data.status
            })
        },

        statusList({commit, rootGetters}){
            return axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/incomingstatuses',
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            }).
            then(res=>{
                commit('statusList', res.data)
            })
        },

        loadData({dispatch}, incomingId){
            return Promise.all([
              dispatch('incomingDetail', incomingId),
              dispatch('elementList', incomingId),
              dispatch('statusList'),
            ])
        },
    }
}