
<template>
  <div class="card">
    <div style="">
      <h1>404</h1>
      <h2>Page not found.</h2>
    </div>
  </div>
  </template>

<style>

.card{
  text-align: center; 
  display: flex; 
  align-items: center;   
  justify-content: center; 
  min-height: 100vh;
  color:grey;
}

</style>