<template>
<div style="margin-top: 20vh;">
  <Toast/>
  <div class="logo-div">
      <img class="logo" :src="require(`@/assets/irmagpro-logo.png`)" alt="Логотип IRMAG"/>
  </div>
  
  <div class="my-card">
    <form @submit.prevent="login">

      <InputText id="login" style="width: 100%; margin-bottom: 2%;" class="input-login" v-model="user.login" placeholder="Логин" :disabled="loadingStatus" required/>

      <div style="width: 100%; display: flex; align-items: stretch; flex-direction: column;">
        <Password inputStyle="width: 100%;" inputClass="input-login" v-model="user.password" :feedback="false" toggleMask placeholder="Пароль" :disabled="loadingStatus" required/>
      </div>
      
      <div style="width:100%; display: flex; justify-content: center; padding-top: 25px;">
        <p-button type="submit" class="p-button-outlined button-login" :disabled="loadingStatus">
          <ProgressSpinner v-if="loadingStatus" style="width:30px; height:30px"/>
          <span v-else class="button-label">Войти</span>
        </p-button>
      </div>
      
    </form>
  </div>
</div>
</template>

<script>
import Toast from 'primevue/toast'

export default {
  name: 'LoginView',

  components: {
    Toast
  },

  data(){
    return {
      loadingStatus: false,
      user: {
        login: '',
        password: ''
      }
    }
  },

  methods: {
    async login(){
      this.loadingStatus = true
      await this.$store.dispatch('auth/login', this.user).
      catch(err=>{
        console.log(err.response)
        this.user.password = ''
        this.user.login = ''
        if (!err.response || !err.response.data.error){
          this.$toast.add({severity: 'error', summary: 'Ошибка', detail: 'Ошибка сервера', life: 6000})
          return
        }
        this.$toast.add({severity: 'error', summary: 'Ошибка', detail: err.response.data.error, life: 6000})
      })
      this.loadingStatus = false
    }
  },
}
</script>

<style lang="scss">
@keyframes p-progress-spinner-color {
  from {
    stroke:  #d62d20;
  }
  to {
    stroke:  #0057e7;
  }
}
.p-password .pi{
  font-size: 19px;
}
.logo-div{
  width: 100%;
}
.logo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  @media (min-width:650px){
    width:50%;    
  }
  @media (min-width:1100px){
    width:40%;
  }
  @media (min-width:1700px){
    width:30%;
  }
}
.button-login{
  display: flex; 
  justify-content: center; 
  width:90px; 
  height:35px;
}
.logo-text{
  color:#1AA7EC; 
  font-weight: 550; 
  font-size: 18px; 
  margin-left: 30%;
}
.my-card{
  border-radius:8px; 
  text-align:left;
  margin: 45px 5% 0 5%;
  padding: 5%;
}

@media (min-width:530px){
  .logo-text{
    font-size:25px;
  }
  #app .my-card form .input-login{
    font-size: 20px;
  }
  #app .my-card form .button-label{
    font-size: 20px;
  }
  #app .my-card form .button-login{
    height:40px;
  }
}
</style>
