<template>
  <div class="barcode-scanner">
    <Dialog header="ШК сканер" 
      @show="onShowDialog"
      :visible="visible" 
      :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}"
      :modal=true
    >
      <div ref='cameraTarget' class="camera-target"></div>
    </Dialog>
  </div>
</template>

<script>
import Quagga from 'quagga'
import Dialog from 'primevue/dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'BarcodeScanner',

  components: {
    Dialog,
  },

  props: [
    'visible'
  ],

  data(){
    return {
      lastCode: 0,
      code: null,
    }
  },

  computed: mapGetters({
    activeScanner: 'barcodeScanner/activeScanner'
  }),
  
  methods: {
    async onShowDialog(){
      document.querySelector('.p-dialog-header-close').addEventListener('click', this.basicClose)

      if (this.activeScanner === 'camera'){  
        this.QuaggaProcess()

        this.updateSize()
        window.addEventListener('resize', this.updateSize)
      }
    },

    updateSize(){
      this.$refs.cameraTarget.style.height = (window.innerHeight - 250) + 'px'
    },

    basicClose(){
      this.$emit('change-visible', false)
    },

    onDetectBarcode(){
      this.$emit('detect-barcode', this.code)
    },
    
    QuaggaProcess(){
      Quagga.init({
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: this.$refs.cameraTarget,
            size: 640,
            constraints: {
                width: { min: 640 },
                height: { min: 480 },
            },
          },
          locator: {
            patchSize: 'small',
            halfSample: false,
          },
          numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 4),
          decoder: {
            readers: [{
              format: 'ean_reader',
              config: {},
            }],
          },
          frequency: 10,
          locate: true,
        }, 
        err => {
          if (err){
            this.basicClose()
            this.$emit('barcode-error', err)
            return
          }
          document.querySelector('.p-dialog-mask').style.display = 'flex'
          
          Quagga.start()
      })

      Quagga.onProcessed(result => {
        const drawingCtx = Quagga.canvas.ctx.overlay
        const drawingCanvas = Quagga.canvas.dom.overlay

        if (result){
          if (result.boxes){
            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10))
            result.boxes.filter(box => {
                return box !== result.box
            }).forEach(box => {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 })
            })
          }

          if (result.box){
              Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 })
          }

          if (result.codeResult && result.codeResult.code){
              Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 })
          }
        }
      })

      Quagga.onDetected(result => {
        this.code = String(result.codeResult.code)
        if (this.lastCode === this.code
            && (this.code.length === 8 || this.code.length === 13)
        ){
          this.lastCode = 0          
          Quagga.pause()
          this.onDetectBarcode()
          this.basicClose()
        } else {
          this.lastCode = this.code
        }
      })
    }
  },
}
</script>

<style>
.camera-target{
  position: relative;
}
div.camera-target .drawingBuffer{
  object-fit: cover;
  position: absolute; 
  width: 100%;
  height: 100%;

}
div.camera-target video{
  object-fit: cover;
  position: absolute; 
  width: 100%;
  height: 100%;
}
</style>