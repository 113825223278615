import { createStore } from 'vuex'
import auth from './auth'
import incoming from './incoming'
import incomingDetail from './incomingDetail'
import barcodeScanner from './barcodeScanner'
import elementPivotTable from './elementPivotTable'
import elementChangesManager from './elementChangesManager'

export default createStore({
  modules: {
    auth,
    incoming,
    incomingDetail,
    barcodeScanner,
    elementPivotTable,
    elementChangesManager,
  }
})
