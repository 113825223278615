import axios from 'axios'

export default {
    namespaced: true,
    actions: {
        postIncomingElementChanges({rootGetters}, data){
            return axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/incomingelementchanges',
                data,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            })
        },
        patchIncomingElementChanges({rootGetters}, {data, incomingElementChangesId}){
            return axios({
                method: 'patch',
                url: process.env.VUE_APP_API_URL + '/incomingelementchanges/' + incomingElementChangesId,
                data,
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            })
        },

        IncomingElementChanges({dispatch, commit}, {element, incomingId}){
            var out = null

            if (element.incomingElementChanges && element.incomingElementChanges.amount >= 0){
                const data = {
                    amount: element.amountModel,
                }
                out = dispatch('patchIncomingElementChanges', {data, incomingElementChangesId: element.incomingElementChanges.id})
            } else {
                const data = {
                    incoming: incomingId,
                    amount: element.amountModel,
                    element: element.element.id
                }
                out = dispatch('postIncomingElementChanges', data)
            }
            
            return out.
            then((res)=>{
                commit('incomingDetail/updateElement', res.data, { root: true })
                commit('incomingDetail/createIncomingChangesWithoutRequest', null, { root: true }) //При создании изменения в товаре, на сервере автоматически создается изменение прихода. Создаем его и здесь.
                commit('elementPivotTable/updateElement', res.data, { root: true })
                commit('elementPivotTable/elementGroupedList', null, { root: true })
            })
        },
    }
}