<template>
  <div class="element-detail" >
    <Dialog :header="'Товар Id: '+data.element.element.id" 
      @show="onShowDialog"
      :visible="data.visible" 
      :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}"
      :modal=true
    >
      <div style="text-align: center; width: 100%;">
        <img class="image-show" :src="data.element.element.image"/>
      </div>
      <div class="info-panel">
        <p style="margin: 0;" class="head">Наименование:</p>
        <span class="line">{{data.element.element.name}}</span>
        <div class="divider"></div>
        <p class="head">ШК: <span class="line">{{data.element.element.barcode}}</span></p>
        <div class="divider"></div>
        <p class="head">Артикул: <span class="line">{{data.element.article ? data.element.article: "-"}}</span></p>
        <div class="divider"></div>
        <p class="head" style="margin-bottom:0;">Цена: <span class="line">{{data.element.element.price}}</span></p>
      </div> 
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  name: 'elementDetail',

  components: {
    Dialog
  },

  props: [
    'data',
  ],

  methods: {
    onShowDialog(){
      document.querySelector('.p-dialog-mask').addEventListener('click', this.closeForClickOutsideDialogArea)

      document.querySelector('.p-dialog-header-close').addEventListener('click', this.basicClose)
    },

    closeForClickOutsideDialogArea(event){
      if (!event.target.offsetParent || !event.target.offsetParent.classList.contains('p-dialog')){
        this.basicClose()
      }
    },

    basicClose(){
      this.$emit('change-visible', false)
    }
  }
   
}
</script>

<style>
  .image-show{
    width: 50%;
  }
  .info-panel{
    margin-top:5%;
  }
  .head{
    font-weight: 600;
    color: #495057;
    transition: box-shadow 0.2s;
  }
  .line{
    font-weight:900;
    color: black;
  }
  body .p-dialog-header-close-icon{
    font-size: 35px;
  }
  body .p-dialog .p-dialog-header .p-dialog-header-icon{
    width: 60px;
    height: 60px;
  }
  .divider
  {
    height: 1px;	
    background-color:#edeff1;
    width: 100%;
    margin: 8px 0 8px 0;
  }
  @media (min-width:530px){
   .info-panel{
    font-size: 19px;
   }
  }

  @media (min-height:778px){
   .image-show{
    width: 100%;
   }
  }
</style>