<template>
  <div class="incoming-detail">
    <Toast/>
    <NavBar 
      style="position: sticky; top: 0px;"
      :items="breadcrumbItems"
    />
    
    <div style="overflow:hidden;">
      <Panel header="Заказ" class="order-panel">
        <div v-if="!storeLoading">
          <p class="head" style="margin-top: 0;">Заказ №:   <span class="line">{{incomingDetail.order.id}}</span></p>
          <p >Приход №:   <span>{{incomingDetail.id}}</span></p>
          <p class="head" >Дата доставки:  <span class="line">{{deliveryDate}}</span></p>
          <p class="head" >Статус: <span class="line">{{ statusList.find(item => item.shortname === incomingDetail.statusModel).name }}</span></p>
          <div style="margin-bottom:0;">        
            <p style="margin: 0;" class="head">Комметарий:</p>
            <Textarea :autoResize=true style="width:100%; resize: vertical;" v-model="incomingDetail.commentModel" :disabled="this.disableEditIncoming()"/>
          
            <p-button 
              class="p-button-secondary p-button-sm p-button-outlined button-change-comment" 
              type="button" 
              label="Изменить комментарий" icon="pi pi-check"
              :disabled="this.loadingStatus.comment || this.disableEditIncoming()"
              @click="saveComment"
            >
              <div v-if="loadingStatus.comment" class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </p-button>
          </div>
        </div>
      </Panel>
      <Panel header="Контакты клиента" class="client-contact-panel">
        <div v-if="!storeLoading" >     
          <p class="head" style="margin-top: 0;">Имя:
            <span class="line">{{ incomingDetail.user.fullname ? incomingDetail.user.fullname:'Скрыто'}}</span>
          </p>
          <p class="head" style="margin-bottom:0;">Телефон:
            <component :is="telephone[0]" :href="'tel:' + telephone[1]" class="line"> {{telephone[1]}}</component>
          </p>
        </div>
      </Panel>

      <DataTable 
        :value="elementList" 
        responsiveLayout="scroll"
        class="p-datatable-sm table"
        :rowClass="rowTableClass"
        dataKey="id"
        showGridlines
        v-model:filters="filters"
      >
        <template #empty>
            Нет товаров.
        </template>
        <template #header>
          <div style="display:flex; justify-content:space-between;">
              <p-button type="button" icon="pi pi-filter-slash" label="" class="p-button-secondary p-button-outlined" @click="initFilters()"/>

              <p-button v-show="activeScanner" @click="visibleBarcodeScanner = true" type="button" class="scanner-button p-button-secondary p-button-outlined" >
                <img v-show="activeScanner === 'bluetooth'" class="img-scanner-bluetooth-table" :src="require(`@/assets/bluetooth.svg`)"/>
                <i v-show="activeScanner === 'camera'" class="pi pi-camera img-scanner-camera-table"/>
              </p-button>

              <span class="p-input-icon-left" >
                  <i class="pi pi-search" />
                  <InputText placeholder="Поиск" v-model="filters['global'].value"/>
              </span>

          </div>
        </template>
        <Column header="№" field="ind" class="table-no">
          <template #body="slotProps" >
            <div @click="onShowElementDetail(slotProps.data)" class="table-show-element-detail">
              {{slotProps.data.ind}}
            </div>
          </template>
        </Column>
        <Column field="article" 
          class="table-article" 
          headerClass="table-header-break"
          header="Артикул"
        >
          <template #body="slotProps" >
            <div @click="onShowElementDetail(slotProps.data)" class="table-show-element-detail">
              {{slotProps.data.article ? slotProps.data.article: "-"}}
            </div>
          </template>
        </Column>
        <Column field="element.name" class="table-element-name" header="Наименование">
          <template #body="slotProps">
            <div @click="onShowElementDetail(slotProps.data)" class="table-show-element-detail">
              {{slotProps.data.element.name}}
            </div>
          </template>
        </Column>
        <Column field="element.barcode" class="table-barcode" header="ШК"></Column>
        <Column field="amountModel" headerClass="table-header-break" class="table-amount" header="Кол-во">
          <template #body="slotProps">
            <InputNumber v-model="slotProps.data.amountModel" mode="decimal" 
              buttonLayout="vertical" showButtons :min="0"
              decrementButtonClass="p-button-sm p-button-secondary p-button-outlined table-amount-button" 
              incrementButtonClass="p-button-sm p-button-secondary p-button-outlined table-amount-button" 
              incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
              :inputClass="'table-amount-input '+rowTableClass(slotProps.data)"
              :disabled="this.disableEditIncoming()"
            />
          </template>
        </Column>
        <Column field="element.price" headerClass="table-header-break" header="Цена" class="table-price"></Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
          <template #body="slotProps">
            <p-button 
              @click="changeElement(slotProps.data)" 
              class="p-button-secondary table-button p-button-outlined" type="button" iconClass="table-button-icon" icon="pi pi-check"
              :disabled="loadingStatus.elementChanges || this.disableEditIncoming()"
            >
              <div v-if="loadingStatus.elementChanges" class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </p-button>
          </template>
        </Column>
        <template #footer>
          Всего: {{elementList ? elementList.length : 0 }}
        </template>
      </DataTable>
      <p-button 
        class="p-button-success p-button-outlined button-send-changes" 
        type="button" 
        label="Отправить изменение прихода" icon="pi pi-check"
        v-visible = "showSendButton"
        @click="sendIncomingChanges()"
      />
    </div>
    <ConfirmDialog/>
    <ElementDetail v-if="elementDetail.element" :data="elementDetail" @change-visible="ev => elementDetail.visible = ev"/>
    <BarcodeScanner :visible="visibleBarcodeScanner" @barcode-error="onErrorBarcodeScanner" @change-visible="ev => visibleBarcodeScanner = ev" @detect-barcode="onDetectedBarcode"/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import param from '../parameters.js'
import { FilterMatchMode } from 'primevue/api'
import ElementDetail from '@/components/ElementDetail.vue'
import BarcodeScanner from '@/components/BarcodeScanner.vue'

export default {
  name: 'IncomingDetailView',

  components: {
    NavBar,
    ConfirmDialog,
    Toast,
    ElementDetail,
    BarcodeScanner
  },

  data(){
    return {
      param: {},
      elementDetail: {
        visible: null, 
        element: null
      },
      incomingId: null,
      loadingStatus: {
        comment: false,
        elementChanges: false,
      },
      filters: null,
      visibleBarcodeScanner: false,
    }
  },

  created(){
    this.initFilters()
    this.param = param
  },

  async mounted(){
    this.incomingId = this.$route.params.incomingId

    await this.$store.dispatch('incomingDetail/loadData', this.incomingId).
    catch(err=>{
      console.log(err.response) 
      if (!err.response){
        this.$toast.add({severity: 'error', summary: 'Ошибка', detail: 'Ошибка подключения к сети', life: 3000})
      }
      if (err.response && err.response.status === 404){
        this.$router.push({name: 'notFound'})
      }  
    })
    this.$store.commit('incomingDetail/setLoadingStatus', false) 

    const elements = document.querySelectorAll('td .table-show-element-detail')
    elements.forEach(item => item.parentElement.addEventListener('click', this.clickChildren))
     
    window.addEventListener('beforeunload', () => {
      this.saveScrollPosition()
    })

    let top = JSON.parse(localStorage.getItem('scrollPosition') || '{}').incomingDetail
    this.$nextTick(()=>{
      window.scrollTo(0, top)
    })
  },

  beforeRouteLeave(){
    let scrollPosition = JSON.parse(localStorage.getItem('scrollPosition') || '{}')
    delete scrollPosition.incomingDetail
    localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition))
  },

  computed: { 
    ...mapState({
      incomingDetail: state => state.incomingDetail.incomingDetail,
      elementList: state => state.incomingDetail.elementList,
      statusList: state => state.incomingDetail.statusList,
      storeLoading: state => state.incomingDetail.loadingStatus,
    }),
    ...mapGetters({
      telephone: 'incomingDetail/telephone',
      deliveryDate: 'incomingDetail/deliveryDate',
      isAllElementsProcessed: 'incomingDetail/isAllElementsProcessed',
      breadcrumbItems: 'incomingDetail/breadcrumbItems',

      activeScanner: 'barcodeScanner/activeScanner'
    }),
    showSendButton(){
      return !this.storeLoading && 
              this.isAllElementsProcessed && 
              !this.disableEditIncoming()
    }
  },

  methods: {
    disableEditIncoming(){
      return this.incomingDetail && this.incomingDetail.statusModel !== param.STATUS_OPEN
    },

    onErrorBarcodeScanner(err){
      this.$toast.add({severity: 'error', summary: 'ШК сканнер', detail: err, life: 4000})
    },

    onDetectedBarcode(code){
      this.filters['global'].value = code
    },

    saveScrollPosition(){
      let scrollPosition = JSON.parse(localStorage.getItem('scrollPosition') || '{}')
      scrollPosition.incomingDetail = window.scrollY
      localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition))
    },

    onShowElementDetail(element){
      this.elementDetail.element = element
      this.elementDetail.visible = true
    },

    rowTableClass(data){
      if (data.incomingElementChanges && data.incomingElementChanges.amount === data.amount)
        return 'row-green'
      if (data.incomingElementChanges && data.incomingElementChanges.amount === 0)
        return 'row-red'      
      if (data.incomingElementChanges && data.incomingElementChanges.amount != data.amount)
        return 'row-yellow'
      return null
    },

    async saveComment(){
      this.loadingStatus.comment = true
      await this.$store.dispatch('incomingDetail/saveComment', this.incomingId).
      then(()=>{
        this.$toast.add({severity: 'success', summary: 'Кооментарий', detail: 'Комментарий изменен', life: 3000})
      }).
      catch(err=>{
        console.log(err.response)
        this.$toast.add({severity: 'error', summary: 'Комментарий', detail: 'Ошибка при изменении комментария', life: 3000})
      })
      this.loadingStatus.comment = false
    },
    
    sendIncomingChanges(){
      this.loadingStatus.elementChanges = true
      this.$store.dispatch('incomingDetail/changeStatusIfNeed', this.incomingId).
      then(()=>{
        this.loadingStatus.elementChanges = false
        var statusToOutput = this.statusList.find(item => item.shortname === this.incomingDetail.statusModel).name
        this.$toast.add({severity: 'success', summary: 'Приход', detail: `Изменения в приходе сохранены - статус: "${statusToOutput}"`, life: 3000})
        this.$router.push({name: 'incomingList'})
      }).
      catch(err => {
        this.loadingStatus.elementChanges = false
        console.log(err.response)
        this.$toast.add({severity: 'error', summary: 'Приход', detail: 'Ошибка при сохранении изменения в приходе', life: 3000})
      })
    },

    /*
      // Удалить изменения в приходе
      // Может быть как-нибудь понадобится.
      deleteIncomigChanges(){
      this.$confirm.require({
        message: 'Вы уверены что хотите отменить изменения в приходе?',
        header: 'Отменить изменения в приходе',
        icon: 'pi pi-trash',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-text p-button-secondary',
        accept: () => {
          this.loadingStatus.elementChanges = true
          this.$store.dispatch('incomingDetail/deleteIncomingChanges', this.incomingId).
          then(async ()=>{
            await this.$store.dispatch('incomingDetail/loadData', this.incomingId)
            this.loadingStatus.elementChanges = false
            this.$toast.add({severity: 'info', summary: 'Приход', detail: 'Изменения в приходе отменены', life: 3000})
          }).
          catch(err=>{
            console.log(err.response)
            this.$toast.add({severity: 'error', summary: 'Приход', detail: 'Ошибка при отмене изменений', life: 3000})
          })
        },
        acceptLabel: 'Да',
        rejectLabel: 'Нет'
      })
    },*/

    clickChildren(event){
      if (event.target.localName != 'td') return
      event.target.firstElementChild.click()
    },

    async changeElement(element){
      this.loadingStatus.elementChanges = true
      await this.$store.dispatch('elementChangesManager/IncomingElementChanges', {element, incomingId: this.incomingId}).
      catch(err=>{
        console.log(err.response)
        this.$toast.add({severity: 'error', summary: 'Товары', detail: 'Ошибка при подтверждении товара', life: 3000})
      })
      this.loadingStatus.elementChanges = false
    },
    
    initFilters(){
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },
}
</script>

<style>
  @import '../assets/incoming.css';
  @import '../assets/spinner.css';
  div.incoming-detail .img-scanner-bluetooth-table{
    margin: auto;
    height: 1.5rem;
    filter: invert(49%) sepia(7%) saturate(1291%) hue-rotate(155deg) brightness(94%) contrast(92%);
  }
  div.incoming-detail .img-scanner-camera-table{
    margin: auto;
    font-size: 1.4rem;
  }
  div.incoming-detail .scanner-button{
    margin-left: auto;
    margin-right: 15px;
    padding: 0;
    height: 2.2rem;
    width: 2.2rem;
    text-align: center;
  } 
  .button-change-comment{
    margin-top: 0.25rem; 
    height: 25px; 
    font-size: 14px;
  }
  .incoming-detail{
    padding:0 1% 1rem 1%;
  }
  .incoming-detail .button-send-changes{
    margin: 0.4rem;
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .incoming-detail .head{
    font-weight: 600;
    color: #495057;
    transition: box-shadow 0.2s;
  }
  .incoming-detail .line{
    font-weight:900;
    color: black;
  }
  .incoming-detail .table{
    margin-bottom:0.5rem;
    font-size: 14px;
  }
  .table-no{
    cursor: pointer;
  }
  .incoming-detail .table-article{
    min-width: 52px; 
    cursor: pointer;
  }
  .incoming-detail .table-element-name{
    word-wrap: break-word; 
    max-width: 120px;
    cursor: pointer;
  }
  #app .incoming-detail .table-amount-input{
    font-weight: 800; 
    font-size: 16px; 
    width: 100%; 
  }
  #app .incoming-detail .p-inputnumber-button{
    height: 25px;
  }
  #app .incoming-detail .table-button{
    width: 25px;
    height: 25px;
  }
  .incoming-detail .table-button-icon{
    font-size: 15px;
  }
  .table-header-break{
    word-wrap: break-word; 
    overflow-wrap:break-word; 
    word-break:break-all
  }
  .table-amount{
    min-width: 72px;
  }
  .table-barcode{
    display: none;
  }
  .incoming-detail .client-contact-panel{
    margin-bottom:10px;
  }
  #app .incoming-detail .order-panel{
    margin-bottom:10px;
  }

  #app .incoming-detail .p-datatable-thead .p-column-header-content{
    cursor: text;
  }
  @media (min-width:530px){
    #app .incoming-detail .button-change-comment{
      height: 35px; 
      font-size: 16px;
    }
    .incoming-detail .table{
      font-size: 19px;
    }
    .incoming-detail .table-element-name{
      max-width: none;
    }
    #app .incoming-detail .table-amount-input{
      font-size: 19px; 
    }
    .table-amount{
      min-width: 82px;
    }
    .incoming-detail .button-send-changes{
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
    }
    #app .incoming-detail .table-button{
      width: 35px;
      height: 35px;
    }
    .incoming-detail .client-contact-panel{
      font-size: 19px;
    }
    #app .incoming-detail .order-panel{
      font-size: 19px;
    }
    #app .incoming-detail .order-panel .p-inputtext{
      font-size: 19px;
    }
  }

</style>