import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputNumber from 'primevue/inputnumber'
import Panel from 'primevue/panel'
import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import ProgressSpinner from 'primevue/progressspinner'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'

const app = createApp(App).use(store).use(router)

app.use(PrimeVue)
app.use(ConfirmationService)
app.use(ToastService)

app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('p-button', Button)
app.component('Textarea', Textarea)
app.component('InputNumber', InputNumber)
app.component('Panel', Panel)
app.component('Password', Password)
app.component('InputText', InputText)
app.component('ProgressSpinner', ProgressSpinner)

app.directive('visible', (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
})

app.mount('#app')