<template>
  <div class="wrap">
    <div class="nav">
      <div class="element-pivot-table-button">
        <i class="pi pi-book" style="font-size: 22px; cursor: pointer; margin: auto;" @click="onShowElementPivot"/>
      </div>
      <Breadcrumb :exact=true :home="home" :model="items" class="menu"></Breadcrumb>
      <div class="setting" :ref="containerRef">
        <i :class="menuScannerClass" style="font-size: 26px; cursor: pointer;" @click="this.menuPopup = !this.menuPopup"/>
        <div class="menu-panel" v-show="menuPopup">
          <div style="padding-bottom: 10px; display: flex;">
            <i :class="imgScannerCameraClass"/>
            <InputSwitch class="switch-scanner" v-model="scannerSwitch.find(el => el.name === 'camera').value" @change="switchScanner"/>
          </div>
          <div style="display: flex;">
            <img :class="imgScannerBluetoothClass" :src="require(`@/assets/bluetooth.svg`)"/>
            <InputSwitch class="switch-scanner" v-model="scannerSwitch.find(el => el.name === 'bluetooth').value" @change="switchScanner"/>
          </div>
        </div>
        <i class="pi pi-sign-out" style="font-size: 22px; cursor: pointer;" @click="logout"/>
      </div>
    </div>
    <div style="text-align: center;">
      <p class="name">{{sellerName}}</p>
    </div>
    <ElementPivotTable :visible = "elementPivotTable.visible" @change-visible="ev => elementPivotTable.visible = ev"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import Breadcrumb from 'primevue/breadcrumb'
import InputSwitch from 'primevue/inputswitch'
import ElementPivotTable from './ElementPivotTable.vue'

export default {
  components: {
    Breadcrumb, 
    InputSwitch,
    ElementPivotTable
  },

  props: [
    'items'
  ],

  container: null,

  data(){
    return {
      home: {label: 'Заказы', to: '/'},
      menuPopup: false,
      elementPivotTable: {
        visible: false
      }
    }
  },

  created(){
    this.$store.dispatch('elementPivotTable/elementList')
  },

  watch: {
    menuPopup(newValue){
      if (newValue){
        this.bindOutsideClickListener()
      } else {
        this.unbindOutsideClickListener()
      }
    }
  },

  beforeUnmount(){
    this.unbindOutsideClickListener()
  },

  methods: {
    switchScanner(){
      this.$store.commit('barcodeScanner/switchScanner', this.scannerSwitch)
    },

    bindOutsideClickListener(){
      if (!this.outsideClickListener){
        this.outsideClickListener = (event) => {
          const isOutsideContainer = this.container && !this.container.contains(event.target)
          if (isOutsideContainer && this.menuPopup){
            this.menuPopup = false
          }
        }
        document.addEventListener('click', this.outsideClickListener)
      }
    },

    unbindOutsideClickListener(){
      if (this.outsideClickListener){
        document.removeEventListener('click', this.outsideClickListener)
        this.outsideClickListener = null
      }
    },

    containerRef(el){
      this.container = el
    },

    onShowElementPivot(){
      this.elementPivotTable.visible = true
    },

    logout(){
      this.$store.dispatch('auth/logout')
      this.$router.push({name: 'login'})
    },  
  },

  computed: {
    ...mapState({
      sellerName: state => state.auth.name,
      scannerSwitch: state => state.barcodeScanner.scannerSwitch,
    }),
    ...mapGetters({
      activeScanner: 'barcodeScanner/activeScanner'
    }),

    imgScannerBluetoothClass(){
      let out = 'img-scanner-bluetooth'
      if (this.activeScanner === 'bluetooth'){
        out += ' svg-blue' 
      }

      return out
    },

    imgScannerCameraClass(){
      let out = 'pi pi-camera img-scanner-camera'
      if (this.activeScanner === 'camera'){
        out += ' svg-blue' 
      }

      return out
    },

    menuScannerClass(){
      let out = 'pi pi-cog' 
      if (this.activeScanner){
        out += ' svg-blue' 
      }

      return out
    },
  }
}
</script>

<style>
.element-pivot-table-button{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  flex-grow: 0; 
}
div.menu-panel .img-scanner-camera{
  font-size: 32px;
  margin-right: 10px;
}
div.menu-panel .img-scanner-bluetooth{
  margin-right: 10px;
  width: 34px;
}
.switch-scanner{
  margin: auto; 
  margin-right: 0;
}
.svg-blue{
  filter: invert(32%) sepia(96%) saturate(382%) hue-rotate(159deg) brightness(57%) contrast(98%);
}
.menu-panel{
  padding: 1.2rem;
  box-sizing: border-box;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  position: absolute;
  width: auto;
  top:45px;
  right:10px;
  z-index: 5;
}
.setting{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 4%;
  width: 100px;
  flex-grow: 0; 
}
.nav{
  display:flex; 
  justify-content:space-between; 
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding-top:1px;
}
.name{
  white-space: nowrap; 
  text-overflow: ellipsis; 
  overflow: hidden; 
  display: inherit;
  margin: 10px 0 10px 0;
}
#app div .menu{
  border: none;
  flex-grow: 2; 
}
.wrap{
  background-color: white; 
  z-index: 10;
}
</style>
