import { createRouter, createWebHistory } from 'vue-router'
import IncomingListView from '../views/IncomingListView.vue'
import IncomingDetailView from '../views/IncomingDetailView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import LoginView from '../views/LoginView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'incomingList',
    component: IncomingListView,
    meta: { 
      requiresAuth: true,
    }
  },
  {
    path: '/incoming-detail/:incomingId',
    name: 'incomingDetail',
    component: IncomingDetailView,
    meta: { 
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFoundView
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'notFound' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition){
    return savedPosition
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)){
    if (store.getters['auth/isAuthorized']){
      next()
    } else {
      router.replace('/login')
      next()
    }
  } else {
    next()
  }
})

export default router
