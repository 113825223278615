<template>
  <router-view/>
</template>

<style lang="scss">
  body{
    padding: 0;
    margin:0;
    font-family: Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
  }
</style>
