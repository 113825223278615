import axios from 'axios'
import moment from 'moment'

export default {
    namespaced: true,

    state: {
        incomingList: [],
    },

    mutations: {
        incomingList(state, data){
            data.forEach(el => {
                el.status = el.incomingChanges && el.incomingChanges.status ? el.incomingChanges.status : el.status
                el.updatedAt = el.incomingChanges && el.incomingChanges.updatedAt ? el.incomingChanges.updatedAt : el.updatedAt
            })
            data.sort((a, b) => 
                a.status.shortname.localeCompare(b.status.shortname) || 
                moment(b.order.deliveryDate, 'DD.MM.YYYY hh:mm:ss').toDate() - moment(a.order.deliveryDate, 'DD.MM.YYYY hh:mm:ss').toDate()
            )

            let ind = 1
            let elPrev = data[0]
            data.forEach( el => {
                if (el.status.shortname !== elPrev.status.shortname) {
                    ind = 1
                }                      
                el['ind'] = ind
                ind += 1
                elPrev = el
            })
            state.incomingList = data
        },
        logout(state){
            state.incomingList = []
        }
    },

    actions: {
        incomingList({commit, rootGetters}){
            return axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/incomings',
                params: {limit: 1000},
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            }).
            then(res=>{
                commit('incomingList', res.data)
            })
        },
    }
}