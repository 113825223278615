import axios from 'axios'
import param from '../parameters.js'

export default {
    namespaced: true,
    state: {
        elementList: [],
        elementGroupedList: [],
        loadingStatus: true
    },
    getters: {

    },
    mutations: {
        setLoadingStatus(state, status){
            state.loadingStatus = status
        },

        elementList(state, data){  
            let indForDel = []
            data.forEach((el, ind) => {
                if (el.amount <= 0) //Удаляем элементы с нулевым(или меньшим) к-вом товара (Есть возможность что с сервера придут товары с 0 к-вом, оператору их видеть не нужно)
                    indForDel.push(ind)

                el['amountModel'] = el.incomingElementChanges && el.incomingElementChanges.amount >= 0 ? el.incomingElementChanges.amount : el.amount
            })

            for (var i = indForDel.length - 1; i >= 0; i--)
                data.splice(indForDel[i], 1)
            state.elementList = data
        },
        
        elementGroupedList(state){  
            if (!state.elementList.length){
                state.elementGroupedList = []
                return
            }

            let currEl = state.elementList[0]
            let groupedData = []
            let totalAmount = 0
            let totalModelAmount = 0
            state.elementList.forEach(el => {
                if (currEl.element.id !== el.element.id){
                    groupedData.push({...currEl.element, article: currEl.article, totalAmount, totalModelAmount})
                    currEl = el
                    totalAmount = 0
                    totalModelAmount = 0
                }                
                
                totalAmount += el.amount
                totalModelAmount += el.amountModel
            })
            groupedData.push({...currEl.element, article: currEl.article, totalAmount, totalModelAmount})
            
            groupedData.sort((a, b) => 
                a.name.localeCompare(b.name)
            )
            groupedData.forEach((el, ind) => {
                el['ind'] = ind + 1
            })

            state.elementGroupedList = groupedData
        },

        updateElement(state, data){
            const ind = state.elementList.findIndex(item => item.element.id === data.element.id && item.incoming.id === data.incoming.id)
            state.elementList[ind] = data
            state.elementList.forEach((el, ind) => {
                el['ind'] = ind + 1
                el['amountModel'] = el.incomingElementChanges && el.incomingElementChanges.amount >= 0 ? el.incomingElementChanges.amount : el.amount
            })
        },

        logout(state){
            state.elementList = []
            state.elementGroupedList = []
            state.loadingStatus = true
        }
    },
    actions: {
        elementList({commit, rootGetters}){
            return axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/elementspivottable',
                params: {limit: 2000, status: param.STATUS_OPEN},
                headers: {'Content-Type': 'application/json', ...rootGetters['auth/header']},
            }).
            then(res=>{
                commit('elementList', res.data)
                commit('elementGroupedList')
            })
        },

    }
}