<template>
  <div ref='modal' class="element-pivot-table modal">
    <div class="modal-content">

      <div class="dialog-header">
        <span class="dialog-title">Сводная таблица товаров</span>
        <div class="dialog-header-icons">
          <p-button 
            ref="closeButton"
            class="p-button-rounded p-button-secondary p-button-text header-close" 
            type="button"
            @click="basicClose"
          >
            <i class="pi pi-times" style="font-size: 35px; color: #6c757d;"></i>
          </p-button>
        </div>
      </div>

      <div class="dialog-body">
        <DataTable 
          :value="elementGroupedList" 
          responsiveLayout="scroll"
          class="p-datatable-sm table"
          dataKey="id"
          showGridlines
          v-model:expandedRows="expandedRows"
          v-model:filters="filters"
        >
          <template #empty>
            Все заказы набраны.
          </template>
          <template #header>
            <div style="display:flex; justify-content:space-between;">
                <p-button type="button" icon="pi pi-filter-slash" label="" class="p-button-secondary p-button-outlined" @click="initFilters()"/>

                <p-button v-show="activeScanner" @click="visibleBarcodeScanner = true" type="button" class="scanner-button p-button-secondary p-button-outlined" >
                  <img v-show="activeScanner === 'bluetooth'" class="img-scanner-bluetooth-table" :src="require(`@/assets/bluetooth.svg`)"/>
                  <i v-show="activeScanner === 'camera'" class="pi pi-camera img-scanner-camera-table"/>
                </p-button>

                <span class="p-input-icon-left" >
                    <i class="pi pi-search" />
                    <InputText placeholder="Поиск" v-model="filters['global'].value"/>
                </span>
            </div>
          </template>
          <template #groupheader="slotProps" >
            <span>{{ slotProps.data.element.name }}</span>
          </template>
          <Column :expander="true" headerStyle="width: 1rem" />
          <Column header="№" field="ind" class="table-no"/>
          <Column field="article" 
            class="table-article" 
            headerClass="table-header-break"
            header="Артикул"
          >
            <template #body="slotProps" >
              <div @click="onShowElementDetail(slotProps.data)" class="table-show-element-detail">
                {{slotProps.data.article ? slotProps.data.article: "-"}}
              </div>
            </template>
          </Column>
          <Column field="name" class="table-element-name" header="Наименование" headerClass="column-header">
            <template #body="slotProps">
              <div @click="onShowElementDetail(slotProps.data)" class="table-show-element-detail">
                {{slotProps.data.name}}
              </div>
            </template>
          </Column>
          <Column field="barcode" class="table-barcode" header="ШК"></Column>
          <Column field="" header="Суммарное кол-во" headerClass="table-header-break">
            <template #body="slotProps">
              <div style="text-align: center; font-weight: 600; color: #495057">
                <span >{{slotProps.data.totalModelAmount}}</span>
              </div>
            </template>
          </Column>
          <Column field="price" header="Цена" class="table-price" headerClass="table-header-break"></Column>
          <template #expansion="slotProps">
            <DataTable :value="getElementsForIncomings(slotProps.data.id)" responsiveLayout="scroll" :rowClass="rowTableClass">
              <Column field="incoming.order.id" header="№ Заказа">
                <template #body="slotProps" >
                  <div @click="followToIncoming(slotProps.data)" style="cursor:pointer">
                    {{slotProps.data.incoming.order.id}}
                  </div>
                </template>
              </Column>
              <Column field="element.name" class="subtable-element-name" header="Наименование"/>
              <Column field="amountModel" headerClass="table-header-break" class="table-amount" header="Количество">
                <template #body="slotProps">
                  <InputNumber v-model="slotProps.data.amountModel" mode="decimal" 
                    buttonLayout="vertical" showButtons :min="0"
                    decrementButtonClass="p-button-sm p-button-secondary p-button-outlined" 
                    incrementButtonClass="p-button-sm p-button-secondary p-button-outlined" 
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                    :inputClass="'table-amount-input '+rowTableClass(slotProps.data)"
                  />
                </template>
              </Column>
              <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="slotProps">
                  <p-button 
                    @click="changeElement(slotProps.data)" 
                    class="p-button-secondary table-button p-button-outlined" type="button" iconClass="table-button-icon" icon="pi pi-check"
                    :disabled="loadingStatus.elementChanges" 
                  >
                    <div v-if="loadingStatus.elementChanges" class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </p-button>
                </template>
              </Column>
            </DataTable>
          </template>
          <template #footer>
            Всего: {{elementGroupedList ? elementGroupedList.length : 0 }}
          </template>
        </DataTable>
      </div>
    </div>
    <Toast/>
    <ElementDetail v-if="elementDetail.element.element" :data="elementDetail" @change-visible="ev => elementDetail.visible = ev"/>
    <BarcodeScanner :visible="visibleBarcodeScanner" @barcode-error="onErrorBarcodeScanner" @change-visible="ev => visibleBarcodeScanner = ev" @detect-barcode="onDetectedBarcode"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { FilterMatchMode } from 'primevue/api'
import ElementDetail from '@/components/ElementDetail.vue'
import BarcodeScanner from '@/components/BarcodeScanner.vue'
import Toast from 'primevue/toast'

export default {
  components: {
    ElementDetail,
    BarcodeScanner,
    Toast,
  },

  props: [
    'visible'
  ],

  created(){
    this.initFilters()
  },

  mounted(){
    let touchableElement = window
    let touchstartX = null 
    let touchendX = null
    let touchendY = null
    let touchstartY = null
    touchableElement.addEventListener('touchstart', event => {
      touchstartX = event.changedTouches[0].screenX
      touchstartY = event.changedTouches[0].screenY
    }, false)

    touchableElement.addEventListener('touchend', event => {
      touchendX = event.changedTouches[0].screenX
      touchendY = event.changedTouches[0].screenY
      this.handleGesture({touchstartX, touchendX, touchendY, touchstartY})
    }, false)
  },

  data(){
    return {
      filters: null,
      loadingStatus: {
        elementChanges: false,
      },
      expandedRows: [],
      elementDetail: {
        visible: null, 
        element: {
          element: null
        }
      },
      visibleBarcodeScanner: false,
    }
  },

  computed: {
    ...mapState({
      elementList: state => state.elementPivotTable.elementList,
      elementGroupedList: state => state.elementPivotTable.elementGroupedList
    }),
    ...mapGetters({
      activeScanner: 'barcodeScanner/activeScanner'
    }),
  },

  watch: {
    visible(newValue){
      if (newValue){
        this.basicShow()
      }
    },
    '$route.params': 'functionToRunWhenParamsChange',
  },

  methods: {
    handleGesture(ev){
      let deltaX = 80
      let deltaY = 100

      if (ev.touchendX < ev.touchstartX // right swipe
        && Math.abs(ev.touchendX - ev.touchstartX) > deltaX
        && Math.abs(ev.touchendY - ev.touchstartY) < deltaY
      ){ 
        this.basicClose()
      }

      if (ev.touchendX > ev.touchstartX // left swipe
        && Math.abs(ev.touchendX - ev.touchstartX) > deltaX
        && Math.abs(ev.touchendY - ev.touchstartY) < deltaY
      ){ 
        this.basicShow()
      }
    },

    functionToRunWhenParamsChange(){
      this.$router.go()
    },
    followToIncoming(data){
      this.$router.push({ name: 'incomingDetail', params: { incomingId: data.incoming.id}, replace: true})
      this.basicClose()
    },

    rowTableClass(data){
      if (data.incomingElementChanges && data.incomingElementChanges.amount === data.amount)
        return 'row-green'
      if (data.incomingElementChanges && data.incomingElementChanges.amount === 0)
        return 'row-red'      
      if (data.incomingElementChanges && data.incomingElementChanges.amount != data.amount)
        return 'row-yellow'
      return null
    },

    async changeElement(element){
      this.loadingStatus.elementChanges = true
      await this.$store.dispatch('elementChangesManager/IncomingElementChanges', {element, incomingId: element.incoming.id}).
      catch(err=>{
        console.log(err.response)
        this.$toast.add({severity: 'error', summary: 'Товары', detail: 'Ошибка при подтверждении товара', life: 3000})
      })         
      this.loadingStatus.elementChanges = false
    },

    onErrorBarcodeScanner(err){
      this.$toast.add({severity: 'error', summary: 'ШК сканнер', detail: err, life: 4000})
    },

    onDetectedBarcode(code){
      this.filters['global'].value = code
    },

    basicShow(){
      if (!this.$refs.modal) return

      this.$refs.modal.style.display = 'block'
      let style = {
        margin: 0,
        height: '100%',
        overflow: 'hidden'
      }
      for (const property in style)
        document.querySelector('body').style[property] = style[property]
    },

    getElementsForIncomings(elementId){
      return this.elementList.filter(el => el.element.id === elementId)
    },

    onShowElementDetail(element){
      this.elementDetail.element.article = element.article
      this.elementDetail.element.element = element
      this.elementDetail.visible = true
    },

    initFilters(){
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },

    basicClose(){
      if (!this.$refs.modal) return

      this.$refs.modal.style.display = 'none'
      document.querySelector('body').style.overflow = 'auto'
      this.$emit('change-visible', false)
    },
  },
}
</script>

<style>
  @import '../assets/incoming.css';
  @import '../assets/spinner.css';

  body .modal .img-scanner-bluetooth-table{
    margin: auto;
    height: 1.5rem;
    filter: invert(49%) sepia(7%) saturate(1291%) hue-rotate(155deg) brightness(94%) contrast(92%);
  }
  body .modal .img-scanner-camera-table{
    margin: auto;
    font-size: 1.4rem;
  }
  #app .modal .modal-content .table-amount-input{
    font-weight: 400; 
    font-size: 16px; 
    width: 100%; 
  }
  body .modal .scanner-button{
    margin-left: auto;
    margin-right: 15px;
    padding: 0;
    height: 2.2rem;
    width: 2.2rem;
    text-align: center;
  } 
  body .modal .table{
    margin-bottom:0.5rem;
    font-size: 14px;
  }

  body .modal .table-header-break{
    word-wrap: break-word; 
    overflow-wrap:break-word; 
    word-break:break-all;
  }
  
  body .modal .table-no{
    cursor: pointer;
  }
  body .modal .table-article{
    min-width: 55px; 
    cursor: pointer;
  }
  .subtable-element-name{
    word-wrap: break-word;
  }
  body .modal .table-element-name{
    word-wrap: break-word;
    cursor: pointer;
    max-width: 120px;
  }
  body .modal .table-barcode{
    display: none;
  }

  .modal {
    display: none; 
    position: fixed; 
    z-index: 2; 
    padding-top: 30px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    margin: 0;
    overflow: hidden;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    border-radius: 4px;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 100%;
  }

  .dialog-body{
    overflow-y: auto;  
    height: 80vh; 
    padding: 10px; 
    padding-top: 0;
  }

  body .modal .modal-content .dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    padding-left: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    display: flex;
    align-items: center;
  }
  body .modal .modal-content .dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
  }
  body .modal .modal-content div.dialog-header .dialog-header-icons {
    display: flex;
    align-items: center;
    margin:auto;
    margin-right: 0;
  }
  .header-close{
    width: 60px;
    height: 60px; 
    display: flex; 
    justify-content:center
  }

  body .modal .modal-content .p-datatable-thead .p-column-header-content{
    cursor: text;
  }
  @media (min-width:530px){
    body .modal .table{
      font-size: 19px;
    }
    body .modal .table-element-name{
      max-width: none;
    }
  }
</style>