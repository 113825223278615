
export default {
    namespaced: true,
    state: {
        scannerSwitch: JSON.parse(localStorage.getItem('scannerSwitch') || '[{"name":"camera", "value":true}, {"name":"bluetooth", "value":false}]'),
        indexOldSelectedScanner: JSON.parse(localStorage.getItem('scannerSwitch') || '[{"value":true}]').findIndex(el => el.value === true)
    },
    getters: {
        activeScanner(state){
            let ind = state.scannerSwitch.findIndex(el => el.value === true)
            if (ind >= 0){
                return state.scannerSwitch[ind].name
            }
            return null
        }
    },
    mutations: {
        switchScanner(state, data){
            const selected = data.filter(el => el.value === true)

            if (state.indexOldSelectedScanner >= 0 && typeof state.indexOldSelectedScanner === 'number' && selected.length > 1){
                data[state.indexOldSelectedScanner].value = false
            }
            state.indexOldSelectedScanner = data.findIndex(el => el.value === true)

            state.scannerSwitch = data 
            localStorage.setItem('scannerSwitch', JSON.stringify(data))
        },

    }
}