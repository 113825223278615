<template>
  <div class="incoming-list">
    <NavBar/>
    <DataTable 
      class="table-list"
      :loading="loadingStatus"
      :value="incomingList"      
      v-model:expandedRowGroups="expandedRowGroups" 
      rowGroupMode="subheader" 
      groupRowsBy="status.shortname"
      :expandableRowGroups="true" 
      scrollable scrollHeight="80vh"
      responsiveLayout="scroll"
      v-model:selection="selectedIncoming" 
      selectionMode="single" 
      :rowClass="rowTableClass"
      dataKey="id"
      @rowSelect="toIncomingDetail"
      v-model:filters="filters"
    >
      <template #header>
        <div class="table-header">
          Заказы
          <p-button type="button" icon="pi pi-filter-slash" class="p-button-secondary p-button-outlined header-button-right" @click="initFilters()"/>
          <span class="p-input-icon-left" >
              <i class="pi pi-search" />
              <InputText class="incoming-list-search" placeholder="Поиск" v-model="filters['global'].value"/>
          </span>

        </div>
      </template>
      <template #empty>
        Нет заказов.
      </template>
      <template #groupheader="slotProps" >
        <span @click="expandRow(slotProps.data)">{{slotProps.data.status.name}}</span>
      </template>
      <Column header="№" field="ind" class="table-no"/>
      <Column field="order.id" header="№ Заказа"/>
      <Column field="order.deliveryDate" header="Дата доставки"/>
      <Column field="order.deliveryTime.name" header="Время доставки"/>
    </DataTable>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapState } from 'vuex' 
import param from '../parameters.js'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'IncomingListView',

  components: {
    NavBar,
  },

  data(){
    return {
      selectedIncoming: null,
      loadingStatus: true,
      expandedRowGroups: JSON.parse(localStorage.getItem('incomingListExpandedRowGroups')) || [param.STATUS_OPEN],
      filters: null,
    }
  },

  computed: mapState({
    incomingList: state => state.incoming.incomingList,
  }),

  created(){
    this.initFilters()
  },

  async beforeCreate(){
    this.loadingStatus = true
    await this.$store.dispatch('incoming/incomingList').
    catch(err=>{
      console.log(err.response)  
      this.$toast.add({severity: 'error', summary: 'Ошибка', detail: 'Ошибка соединения с сервером'}) 
    })
    this.loadingStatus = false

    let top = JSON.parse(localStorage.getItem('scrollPosition') || '{}').incomingListTable
    if (top){
      document.querySelector('.p-datatable-wrapper').scrollTop = top
    }

    window.addEventListener('beforeunload', () => {
      this.saveExpandedRowGroups()
      this.saveTableScrollPosition()
    })

    const elements = document.querySelectorAll('.p-rowgroup-header')
    elements.forEach(item => item.addEventListener('click', this.emitExpandRow))
  },

  beforeRouteLeave(){
    this.saveExpandedRowGroups()
    this.saveTableScrollPosition()
  },

  methods: {
    saveExpandedRowGroups(){
      localStorage.setItem('incomingListExpandedRowGroups', JSON.stringify(this.expandedRowGroups))
    },

    saveTableScrollPosition(){
      let scrollPosition = JSON.parse(localStorage.getItem('scrollPosition') || '{}')
      scrollPosition.incomingListTable = document.querySelector('.p-datatable-wrapper').scrollTop
      localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition))
    },

    emitExpandRow(ev){
      const el = ev.srcElement.querySelector('button')
      if (el)
        el.click(ev)
    },

    expandRow(data){
      const ind = this.expandedRowGroups.findIndex(item => item === data.status.shortname)
      if (ind >= 0){
        this.expandedRowGroups.splice(ind, 1)
      } else {
        this.expandedRowGroups.push(data.status.shortname)
      }
    },

    rowTableClass(data){
      switch (data.status.shortname){
        case param.STATUS_OPEN:
          return 'row-white'
        case param.STATUS_DONE:
          return 'row-green'
        case param.STATUS_CHANGED:
          return 'row-yellow'
        case param.STATUS_CANCEl:
          return 'row-red'
        default:
          return null
      }
    },

    toIncomingDetail(){
      this.$router.push('incoming-detail/' + this.selectedIncoming.id)
    },
    
    async RefreshIncomingList(){
      this.loadingStatus = true
      await this.$store.dispatch('incoming/incomingList').
      catch(err=>{
        console.log(err.response)   
      })
      this.loadingStatus = false
    },

    initFilters(){
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>
<style>
@import '../assets/incoming.css';
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group-table {
  font-weight: 900; 
  width: 100%; 
  font-size: 14px;
}

#app .incoming-list {
  padding: 0 1% 0 1%;
}

#app div.incoming-list .table-no {
  max-width: 70px;
}

#app .incoming-list .p-rowgroup-header {
  cursor: pointer;
}

#app .incoming-list table .p-row-toggler {
  border: none;
  outline:none;
  box-shadow:none;
}

@media (min-width:530px) {
  #app .incoming-list .table-list{
    font-size: 19px;
  }
}

@media (max-width:530px) {
  #app .incoming-list .incoming-list-search {
    width: 180px;
  }
}

#app div.incoming-list .header-button-right {
  margin-left: auto; 
  margin-right: 15px;
}
</style>
