import axios from 'axios'
import router from '@/router/index.js'

export default {
    namespaced: true,
    state: {
        shortname: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).shortname : null,
        name: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).name : null,
        token: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : null,
    },
    getters: {
        isAuthorized(state){
            if (state.name && state.token){
                return true
            } else {
                return false
            }
            
        },
        header(state){
            if (state.name && state.token){
                return { 'x-api-token': state.token }
            } else {
                return {}
            }
        }
    },
    mutations: {
        login(state, data){
            if (!data.name){
                data.name = data.shortname
            }

            state.token = data.token
            state.name = data.name  
            state.name = data.shortname  
            localStorage.setItem('user', JSON.stringify(data))
        },
        logout(state){
            state.shortname = null
            state.name = null
            state.token = null
            localStorage.removeItem('user')
        }
    },
    actions: {
        login({commit}, data){
            return axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/login',
                headers: {'Content-Type': 'application/json'},
                data
            }).
            then(res=>{
                commit('login', res.data)
                router.push({name: 'incomingList'})
            })
        },
        logout({commit}){
            commit('logout')
            commit('incoming/logout', null, { root: true })
            commit('elementPivotTable/logout', null, { root: true })
            commit('incomingDetail/logout', null, { root: true })
        }
    }
}